import React, { useState } from 'react'
import styled from 'styled-components'
import { useForm, FormProvider } from 'react-hook-form'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers'
import { navigate } from 'gatsby'
import {
  Anchor,
  useResolution,
  TextField,
  Button,
  breakpoints,
  resetPassword,
  InfoMessage,
  DocumentType,
  Country,
  getDniBoliviaSchema,
  showToast,
  checkCustomerDniExist,
} from '@ecommerce/shared'
import { Icon } from '../../components/Icon/Icon'
import Layout from '../../components/Layout'
import { PageContainer } from '../../components/PageContainer'
import { PgPageProps } from '../../types/PgPages'
import withPageTransition from '../../components/withPageTransition'
import { UserMenuMobileNavbar } from '../../components/NavBar/UserMenuMobileNavbar'
import NavBarStaticPage from '../../components/NavBar/NavBarStaticPage'
import { ErrorLevel, sendMessageToSentry, ErrorSource } from '../../utils/sentry'
import secrets from '../../config/secrets'

const cssPrefix = `NewPassword__`

const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .${cssPrefix} {
    &top-bar {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      &-icon {
        width: 30px;
        cursor: pointer;
      }
      &-title {
        text-align: center;
        flex-grow: 1;
        margin: 0 0 12px;
        font-size: 18px;
        @media (${breakpoints.desktop.min}) {
          padding-right: 30px;
        }
      }
    }
    &container {
      width: 310px;
      display: flex;
      flex-direction: column;
      .${cssPrefix} {
        display: flex;
        flex-direction: column;
        align-items: center;
        &label {
          margin-bottom: 32px;
          font-size: 18px;
          text-align: center;
        }
        &form {
          margin-bottom: 32px;
        }
        &submit-button {
          width: 250px;
          margin: 0 auto 15px;
        }
        &status-error {
          display: flex;
          justify-content: center;
          width: 100%;
          color: ${(props) => props.theme.colors.error};

          svg {
            fill: ${(props) => props.theme.colors.error};
          }
        }
      }
    }
  }
`
interface FormData {
  dni: string
  documentType?: string
}

const { documentTypes } = secrets

const ResetPassword = (props: PgPageProps) => {
  const [selectedDocumentType, setSelectedDocumentType] = useState('')
  const { currentCity } = props.pageContext
  const currentSlug = currentCity && currentCity.slug ? currentCity.slug : 'vina-del-mar'
  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [isCheckingDni, setIsCheckingDni] = useState(false)
  const [isDisabled, setIsDisabled] = useState(false)

  const types = documentTypes.filter((item) => item.value !== 'otros')

  const { isDesktop } = useResolution()

  const isBO = secrets.COUNTRY === Country.BOLIVIA

  const schema = (isBolivia = false, type: string) => {
    if (!isBolivia) return Yup.object().shape({})
    return Yup.object().shape({
      dni: getDniBoliviaSchema(type).dni,
    })
  }

  const methods = useForm<FormData>({
    resolver: yupResolver(schema(isBO, selectedDocumentType)),
    mode: 'onSubmit',
  })
  const { register, errors, watch, getValues } = methods
  const watchFields = watch()

  const isSubmitDisabled = !watchFields.dni || !!Object.keys(errors).length || isDisabled

  const onSubmitBO = async () => {
    try {
      if (isLoading) return
      setIsLoading(true)
      const payload = {
        [selectedDocumentType === 'email' || !isBO ? 'email' : 'dni']: getValues().dni,
        originUrl: window.location.href,
      }
      const response = await resetPassword(payload)

      await navigate(`/${currentSlug}/reset-password/success`, {
        state: { email: response.data.email, originUrl: window.location.href, payload },
      })
    } catch (e) {
      const message = e?.response?.data?.message ?? e.message

      setHasError(true)
      setIsLoading(false)
      sendMessageToSentry({
        message: `On reset pass: ${message}`,
        level: ErrorLevel.Error,
        source: ErrorSource.SIGNUP_SERVICE,
        metadata: { error: e, data: { document } },
      })
    }
  }

  const onSubmitCL = async () => {
    try {
      if (isLoading) return
      setIsLoading(true)
      const payload = {
        [selectedDocumentType === 'email' || !isBO ? 'email' : 'dni']: getValues().dni,
        originUrl: window.location.href,
      }
      let response = await checkCustomerDniExist({ dni: getValues().dni })
      if (response.status === 200) {
        if (response.data.customerExists && !response.data.isGuest) {
          response = await resetPassword(payload)
          await navigate(`/${currentSlug}/reset-password/success`, {
            state: { email: response.data.email, originUrl: window.location.href, payload },
          })
        } else {
          showToast({
            content: `No hay una cuenta asociada a ese correo electronico.`,
            title: `No tenemos este correo`,
            type: 'error',
          })
          setIsLoading(false)
          setIsDisabled(true)
        }
      }
    } catch (e) {
      const message = e?.response?.data?.message ?? e.message

      setHasError(true)
      setIsLoading(false)
      sendMessageToSentry({
        message: `On reset pass: ${message}`,
        level: ErrorLevel.Error,
        source: ErrorSource.SIGNUP_SERVICE,
        metadata: { error: e, data: { document } },
      })
    }
  }

  return (
    <Layout
      currentCity={currentCity}
      title="Cambiar contraseña"
      navbar={isDesktop ? NavBarStaticPage : UserMenuMobileNavbar({ city: currentCity })}
    >
      <PageContainer cardMaxWidthDesktop="723px" cardWidthDesktop="723px" cardMinWidthDesktop="723px">
        <PageWrapper>
          <div className={`${cssPrefix}top-bar`}>
            {isDesktop && (
              <Anchor onClick={() => navigate(`/${currentSlug}`)} className={`${cssPrefix}top-bar-icon`}>
                <Icon iconId="arrow_left" size="24" />
              </Anchor>
            )}
            <h2 className={`${cssPrefix}top-bar-title`}>Restablecer contraseña</h2>
          </div>
          <div className={`${cssPrefix}container`}>
            {isBO && (
              <p className={`${cssPrefix}label`}>Selecciona el campo que prefieras para recuperar tu contraseña</p>
            )}
            <FormProvider {...methods}>
              <div className={`${cssPrefix}form`}>
                {isBO ? (
                  <DocumentType
                    isVisible
                    isRecovery
                    setIsCheckingDni={setIsCheckingDni}
                    setSelectedDocumentType={setSelectedDocumentType}
                    selectedDocumentType={selectedDocumentType}
                    types={[
                      ...types,
                      {
                        value: 'email',
                        label: 'Correo electrónico',
                      },
                    ]}
                  />
                ) : (
                  <TextField
                    className={`${cssPrefix}new-password`}
                    label="Correo electrónico"
                    name="dni"
                    type="text"
                    status={!errors.dni ? undefined : 'error'}
                    errorMessage={errors.dni?.message}
                    ref={register}
                    onChange={() => setIsDisabled(false)}
                  />
                )}
              </div>
              <span className={`${cssPrefix}label`}>
                Te enviaremos un correo con un link para restablecer tu contraseña
              </span>
              <Button
                data-testid="reset-submit"
                isDisabled={isSubmitDisabled || isCheckingDni}
                disabled={isSubmitDisabled || isCheckingDni}
                isLoading={isLoading || isCheckingDni}
                type="submit"
                className={`${cssPrefix}submit-button`}
                onClick={isBO ? onSubmitBO : onSubmitCL}
              >
                Continuar
              </Button>
            </FormProvider>

            <InfoMessage
              isHidden={!hasError || isLoading}
              className={`${cssPrefix}status-error`}
              message="Ha habido un error, por favor intenta de nuevo"
            />
          </div>
        </PageWrapper>
      </PageContainer>
    </Layout>
  )
}

export default withPageTransition(ResetPassword)
